import React, {useState, useEffect} from "react";
import './MainHead.css';
import Location from '../../images/location.svg'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import User from "../UI/User/User";
import UserImg from '../../images/user.png';
import Select from "react-select";

function MainHead({title, selectCb, haveSelect, userInfo, grmFullList, citiesOptions, changeCity, currentCity}) {
    const
        [data, setData] = useState(""),
        [selectValue, setSelectValue] = useState('');

    useEffect(() => {
        data === "" ? setData("") : setData(data.label);
    }, [data]);

    const customStyles = {
        dropdownIndicator: () => ({
            display: 'none'
        }),
        indicatorsContainer: () => ({
            display: 'none'
        }),
        container: (provided) => ({
            ...provided,
            height: '32px',
            width: '75%',
            marginRight: '15px'
        }),
        citySelect: () => ({
            width: '25% !important'
        }),
        control: (provided) => ({
            ...provided,
            height: '100%',
            outline: 'none',
            background: 'var(--white)',
            border: '1px solid var(--white)',
            transition: 'all .5s',
            borderRadius: '5px',
            boxShadow: 'none',
            cursor: 'pointer',
            "&:hover": {
                borderColor: '1px solid #D5D4DD',
            }
        }),
        input: (provided) => ({
            ...provided,
            paddingLeft: '8px',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--text-2)',
            paddingLeft: '8px',
            letterSpacing: '0.5px',
            fontSize: '14px',
            opacity: '.6'
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: 0,
            boxShadow: 'none',
            zIndex: '3'
        }),
        menuList: (provided) => ({
            ...provided,
            padding: '0',
            border: '1px solid rgba(213, 212, 221, 0.5)',
        }),
        option: () => ({
            fontSize: '14px',
            textAlign: 'left',
            padding: '0 15px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'all .3s',
            color: '#121212',
            border: '1px solid rgba(213, 212, 221, 0.5)',
            "&:before": {
                content: `url(${Location})`,
                height: '17px',
                width: '17px',
                marginRight: '8px'
            },
            "&:hover": {
                background: 'rgba(234, 68, 51, .1)'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            paddingLeft: '8px',
        }),
    };

    return (
        <div className="main-head">
            <div className="main-head__wrap">
                <h1 className="main-head__title">{title}</h1>
                {haveSelect ? <GooglePlacesAutocomplete
                    apiKey="AIzaSyDebR-ZmV52u73QTs31NnS18N6WGvfDNoM"
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ["ua"]
                        }
                    }}
                    selectProps={{
                        defaultInputValue: data,
                        onChange: (value) => {
                            selectCb(value);
                            setSelectValue('');
                        },
                        value: selectValue,
                        placeholder: "Поиск по адресу",
                        noOptionsMessage: () => "Введіть текст у пошукову строку",
                        styles: customStyles
                    }}
                    onLoadFailed={(error) => {
                        console.log(error);
                    }}
                /> : ''}
                <Select
                    className="city-select"
                    id="citySelect"
                    options={citiesOptions}
                    isSearchable={false}
                    styles={customStyles}
                    value={currentCity}
                    onChange={changeCity}
                    placeholder="Город"
                />
            </div>
            <User name={userInfo.name} position={userInfo.job_title} image={UserImg} />
        </div>
    );
}

export default MainHead;