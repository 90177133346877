import './Navbar.css';
import {Link} from "react-router-dom";
import {ReactComponent as LogoWhite} from "../../../images/logo_white.svg";
import {ReactComponent as HomeIcon} from "../../../images/main-icon.svg";
import {ReactComponent as GmrIcon} from "../../../images/gmr-icon.svg";
import {ReactComponent as ClientsIcon} from "../../../images/clients-icon.svg";
import {ReactComponent as StatisticsIcon} from "../../../images/statistics-icon.svg";
import {ReactComponent as UsersIcon} from "../../../images/users-icon.svg";
import {ReactComponent as ExitIcon} from "../../../images/exit-icon.svg";
import DangerModal from "../../Modals/DangerModal/DangerModal";
import {useState} from "react";
import axios from "axios";
import Api from "../../../Api/Api";

function Navbar({page, logoutCb, userType}) {
    const [openedModal, setOpenedModal] = useState(false);

    const logoutCallback = () => {
        axios({
            method: 'post',
            url: Api.link + 'login/logout',
            data: {
                phone: localStorage.getItem('phone')
            }
        })
            .then(logoutCb)
            .catch((err) => console.log(err));
    };

    if (+userType === 1) {
        return (
            <nav className="navbar">
                <div className="navbar__wrap">
                    <a href="/" className="navbar__logo"><LogoWhite/></a>
                    <ul className="navbar__menu">
                        <li className="navbar__item">
                            <Link to="/" className={`navbar__link${page === 'home' ? ' active' : ''}`}>
                                <HomeIcon/> Главная
                            </Link>
                        </li>
                    </ul>
                </div>
                <button className="navbar__logout navbar__link" onClick={(e) => {
                    e.preventDefault();
                    setOpenedModal(true)
                }}><ExitIcon/> Выйти
                </button>
                <DangerModal
                    opened={openedModal}
                    closeCb={() => setOpenedModal(false)}
                    acceptCb={logoutCallback}
                    title="Вы действительно хотите выйти?"
                />
            </nav>
        );
    } else {
        return (
            <nav className="navbar">
                <div className="navbar__wrap">
                    <a href="/" className="navbar__logo"><LogoWhite/></a>
                    <ul className="navbar__menu">
                        <li className="navbar__item">
                            <Link to="/" className={`navbar__link${page === 'home' ? ' active' : ''}`}>
                                <HomeIcon/> Главная
                            </Link>
                        </li>
                        <li className="navbar__item">
                            <Link to="/gmr" className={`navbar__link${page === 'gmr' ? ' active' : ''}`}>
                                <GmrIcon/> ГМР
                            </Link>
                        </li>
                        <li className="navbar__item">
                            <Link to="/clients" className={`navbar__link${page === 'clients' ? ' active' : ''}`}>
                                <ClientsIcon/> Клиенты
                            </Link>
                        </li>
                        <li className="navbar__item">
                            <Link to="/statistics" className={`navbar__link${page === 'statistics' ? ' active' : ''}`}>
                                <StatisticsIcon/> Статистика
                            </Link>
                        </li>
                        <li className="navbar__item">
                            <Link to="/users" className={`navbar__link${page === 'users' ? ' active' : ''}`}>
                                <UsersIcon/> Пользователи
                            </Link>
                        </li>
                    </ul>
                </div>
                <button className="navbar__logout navbar__link" onClick={(e) => {
                    e.preventDefault();
                    setOpenedModal(true)
                }}><ExitIcon/> Выйти
                </button>
                <DangerModal
                    opened={openedModal}
                    closeCb={() => setOpenedModal(false)}
                    acceptCb={logoutCallback}
                    title="Вы действительно хотите выйти?"
                />
            </nav>
        );
    }
}

export default Navbar;