import './AddGrmModal.css';
import {ReactComponent as CloseIcon} from "../../../images/close_icon.svg";
import ModalOverlay from "../../UI/ModalOverlay/ModalOverlay";
import Select from "react-select";
import React from "react";
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxGl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from "axios";
import Api from "../../../Api/Api";
import toastr from "toastr";
import 'toastr/build/toastr.min.css';
import GrmInfo from "../../GrmInfo/GrmInfo";
import SearchIcon from "../../../images/search_icon.svg";
import BottomArrowIcon from "../../../images/bt_arrow.svg";

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class UpdateGrmModal extends React.Component {
    state = {
        lng: 30.542293,
        lat: 50.467119,
        zoom: 10.54,
        marker: '',
        markerLngLat: [],
        address: '',
        havePlaceholder: false
    }

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.cityRef = React.createRef();
        this.mapContainer = React.createRef();
        this.map = React.createRef();
        mapboxGl.accessToken = 'pk.eyJ1IjoiZG9kb3RhcCIsImEiOiJjbGNleWkwd2wwZTBpM3JuMDliaTczMmdkIn0.Stdk96F101S91AMX_S5feA';
    }

    onClickSubmit = (event) => {
        event.preventDefault();
        let
            data = {},
            errors = 0;

        for (let key of this.formRef.current.elements) {
            if (key.name) {
                if (key.value.trim() === '') {
                    key.classList.add('_error');
                    errors++;
                } else {
                    data[key.name] = key.value;
                }
            }
        }

        if (this.props.grmData.city.trim() === '') {
            this.cityRef.current.controlRef.classList.add('_error');
            errors++;
        }
        if (!this.state.markerLngLat.length) {
            document.querySelector('#editAddress > div').classList.add('_error');
            errors++;
        }

        data.coordinates = {
            lng: this.state.markerLngLat[0],
            lat: this.state.markerLngLat[1],
        };

        if (!errors) this.updateGrm(data);
    }

    updateGrm = (data) => {
        const gmrData = {
            name: data.name,
            phone: localStorage.getItem('phone'),
            company: data.company,
            city: data.city,
            token: localStorage.getItem('token'),
            id: this.props.grmData.id
        };

        if (data.coordinates.lng) gmrData.longitude = data.coordinates.lng;
        if (data.coordinates.lat) gmrData.latitude = data.coordinates.lat;

        axios({
            method: 'post',
            url: Api.link + 'gmr/edit',
            data: gmrData
        }).then(res => {
            console.log(res);
            if (res.data.success) {
                this.props.addCb(false, true);
                this.props.closeCb();
                this.clearForm()
                toastr.clear();
                toastr.success('ГМР успешно изменен');
            }
        }).catch((error) => console.log(error));
    }

    clearForm = (e) => {
        if (e) e.preventDefault();
        if (this.state.marker) this.state.marker.remove();
        this.props.closeCb();
        setTimeout(() => {
            this.props.clearData();
            this.setState({address: ''});
        }, 300);
        this.cityRef.current.controlRef.classList.remove('_error');
        document.querySelector('#editAddress > div').classList.remove('_error');
        for (let key of this.formRef.current.elements) {
            key.classList.remove('_error');
        }
    }

    changeCoordinatesHandler = (value) => {
        this.setState({
            address: value
        })
        geocodeByAddress(value.label)
            .then(results => getLatLng(results[0]))
            .then((data) => {
                const
                    {lat, lng} = data,
                    moveTo = {
                        center: [lng, lat],
                        zoom: 15,
                    };

                if (this.state.marker) this.state.marker.remove();
                this.setState({
                    marker: new mapboxGl.Marker(<div
                        className="marker"></div>).setLngLat([lng, lat]).addTo(this.map.current),
                    markerLngLat: [lng, lat]
                });
                this.map.current.flyTo({
                    ...moveTo,
                    duration: 1000,
                    essential: true
                });
            });
    }

    componentDidMount() {
        if (!this.map.current) {
            this.map.current = new mapboxGl.Map({
                container: this.mapContainer.current,
                style: 'mapbox://styles/dodotap/clceyfxrx001a14qwip9b4ea0',
                center: [this.state.lng, this.state.lat],
                zoom: this.state.zoom,
                resize: true,
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [this.state.lng, this.state.lat]
                        },
                        properties: {
                            title: 'Mapbox',
                            description: 'Washington, D.C.'
                        }
                    }
                ]
            });

            const marker = new mapboxGl.Marker();
            this.map.current.on('click', add_marker.bind(this));

            function add_marker(event) {
                const
                    coordinates = event.lngLat,
                    lng = coordinates.lng,
                    lat = coordinates.lat;

                document.querySelector('#editAddress > div').classList.remove('_error');
                if (this.state.marker) this.state.marker.remove();
                marker.setLngLat(coordinates).addTo(this.map.current);
                this.map.current.flyTo({
                    center: [lng, lat],
                    zoom: 13,
                    duration: 1000,
                    essential: true
                });
                this.setState({marker, markerLngLat: [lng, lat]});
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.grmData.latitude === this.props.grmData.latitude && prevProps.grmData.longitude === this.props.grmData.longitude) return;
        const
            lng = this.props.grmData.longitude,
            lat = this.props.grmData.latitude,
            moveTo = {
                center: [lng, lat],
                zoom: 15,
            };

        if (!lng && !lat) return;
        if (this.state.marker) this.state.marker.remove();
        this.setState({
            marker: new mapboxGl.Marker(<div
                className="marker"></div>).setLngLat([lng, lat]).addTo(this.map.current),
            markerLngLat: [lng, lat]
        });
        this.map.current.flyTo({
            ...moveTo,
            duration: 1000,
            essential: true
        });
    }

    clearAddress = (e) => {
        e.preventDefault();
        if (this.state.marker) this.state.marker.remove();
        this.setState({
            address: '',
            markerLngLat: [],
            marker: '',
            havePlaceholder: true
        });
        this.map.current.flyTo({
            center: [this.state.lng, this.state.lat],
            zoom: 10.54,
            duration: 1000,
            essential: true
        });
    }

    render() {
        const
            customStyles = GrmInfo.modals.customStyles,
            customStylesAddress = Object.assign({}, GrmInfo.modals.customStyles),
            citiesOptions = GrmInfo.citiesOptions;


        customStylesAddress.singleValue = (provided) => ({
            ...provided,
            padding: '0 40px',
        });
        customStylesAddress.placeholder = (provided) => ({
            ...provided,
            fontFamily: 'Roboto, sans-serif',
            color: this.state.markerLngLat.length ? 'var(--text-1)' : 'var(--text-2)',
            paddingLeft: '40px',
            fontSize: '18px'
        });
        customStylesAddress.control = (provided) => ({
            ...provided,
            height: '100%',
            outline: 'none',
            background: 'var(--white)',
            border: '1px solid #9F9F9F',
            transition: 'all .5s',
            borderRadius: '10px',
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            position: 'relative',
            zIndex: '3',
            "&:hover": {},
            '&:before': {
                content: `url(${SearchIcon})`,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '14px',
                height: '23px'
            },
            '&:after': {
                content: `url(${BottomArrowIcon})`,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '17px'
            }
        });
        customStylesAddress.menu = (provided) => ({
            ...provided,
            margin: '0',
            top: '0',
            paddingTop: '50px',
            borderRadius: '10px',
            border: '1px solid #9F9F9F',
            background: 'var(--white)',
            boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            zIndex: 2
        });

        return (
            <div className={`add-modal${this.props.opened ? ' opened' : ''}`}>
                <div className="add-modal__content">
                    <h3 className="add-modal__title">Редактирование ГМР</h3>
                    <button className="add-modal__close" onClick={(e) => {
                        this.props.closeCb();
                        this.clearForm(e);
                    }}><CloseIcon/></button>
                    <form ref={this.formRef} action="src/components/Modals/GrmModals/AddGrmModal"
                          onSubmit={this.onClickSubmit} className="add-modal__form form">
                        <div className="form-control">
                            <label className="label" htmlFor="nameGrm">Имя ГМР</label>
                            <input
                                type="text"
                                name="name"
                                className="input"
                                id="nameGrm"
                                value={this.props.grmData.name}
                                onInput={e => this.props.updateData('name', e.target['value'])}
                                placeholder="Введите имя"/>
                        </div>
                        <div className="form-control zIndex">
                            <label className="label" htmlFor="city">Город</label>
                            <Select
                                name="city"
                                ref={this.cityRef}
                                options={citiesOptions}
                                styles={customStyles}
                                value={
                                    citiesOptions.filter(option => option.label === this.props.grmData.city)
                                }
                                onChange={val => this.props.updateData('city', val.label)}
                                placeholder="Введите город"
                                noOptionsMessage={() => 'Не найдено'}
                            />
                            <button
                                className={`btn-clear${this.props.grmData.city ? '' : ' hidden'}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.updateData('city', '');
                                }}
                            >
                                <CloseIcon/></button>
                        </div>
                        <div className="form-control">
                            <div>
                                <label className="label" htmlFor="companyGrm">Компания</label>
                                <input
                                    type="text"
                                    name="company"
                                    className="input"
                                    id="companyGrm"
                                    value={this.props.grmData.company}
                                    onInput={e => this.props.updateData('company', e.target['value'])}
                                    placeholder="Введите компанию"/>
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="address">Координаты</label>
                            <GooglePlacesAutocomplete
                                apiKey="AIzaSyDebR-ZmV52u73QTs31NnS18N6WGvfDNoM"
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ["ua"]
                                    }
                                }}
                                selectProps={{
                                    placeholder: this.state.markerLngLat.length ?
                                        `${this.state.markerLngLat[0]}, ${this.state.markerLngLat[1]}`
                                        :
                                        this.state.havePlaceholder ? 'Введите адрес ГМР' :
                                        `${this.props.grmData.longitude}, ${this.props.grmData.latitude}`,
                                    noOptionsMessage: () => "Введіть текст у пошукову строку",
                                    id: 'editAddress',
                                    onChange: this.changeCoordinatesHandler,
                                    value: this.state.address,
                                    styles: customStylesAddress
                                }}
                                onLoadFailed={(error) => {
                                    console.log(error);
                                }}
                            />
                            <button
                                onClick={this.clearAddress}
                                className={`btn-clear${this.state.markerLngLat.length === 0 ? ' hidden' : ''}`}><CloseIcon/></button>
                        </div>
                        <div ref={this.mapContainer} className="form-map"></div>
                        <div className="form-buttons">
                            <button className="btn btn--secondary" onClick={this.clearForm}>Отмена
                            </button>
                            <button className="btn btn--primary" type="submit">Изменить</button>
                        </div>
                    </form>
                </div>
                <ModalOverlay cb={this.clearForm}/>
            </div>
        );
    }
}

export default UpdateGrmModal;