import React from "react";

class EditGmr extends React.Component {
    render() {
        return (
            <div className={`gmr-edit ${this.props.state ? ' visible' : ''}`}>
                <div className="gmr-edit__title">Перетащите грм на новый адрес</div>
                <div className="gmr-edit__buttons">
                    <button className="gmr-edit__button btn btn--white" onClick={this.props.removeInfoChangesHandler}>Отмена</button>
                    <button className="gmr-edit__button btn btn--success" onClick={this.props.saveInfoChangesHandler}>Сохранить</button>
                </div>
            </div>
        );
    }
}

export default EditGmr;