import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import Navbar from "../../components/UI/Navbar/Navbar";
import Table from "../../components/UI/Table/Table";
import Pagination from "../../components/UI/Pagination/Pagination";
import MainHead from "../../components/MainHead/MainHead";
import axios from "axios";
import Api from "../../Api/Api";
import RemoveToken from "../../Api/RemoveToken";
import {seo} from "../../helpers/seo";

class Statistics extends React.Component {
    state = {
        tableData: {
            head: ['ID', 'Дата обновления', 'Кол-во клиентов', 'Абон. плата', 'Кол-во ГМР'],
            body: [],
            allData: [],
            bodyLength: 0
        },
        preloading: true,
        page: 1,
    }
    contentPerPage = 10

    loadStatistics() {
        const timeStyles = {
            fontSize: '14px',
            color: 'var(--text-2)',
            marginLeft: '10px'
        };

        axios({
            method: 'post',
            url: Api.link + 'statistic/list',
            data: {
                phone: localStorage.getItem('phone'),
                token: localStorage.getItem('token')
            }
        }).then(res => {
            if (res.data.success) {
                let statistics = [];

                res.data.data.forEach((statistic, key) => {
                    const prevRow = (key === 0) ? res.data.data[key] : res.data.data[key - 1];
                    statistic['clients_change'] = statistic.clients - prevRow.clients;
                    statistic['amount_change'] = statistic.amount - prevRow.amount;
                    statistic['gmr_change'] = statistic.gmr - prevRow.gmr;

                    const
                        date = new Date(statistic.date),
                        day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
                        mouth = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
                        hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
                        minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                    const
                        clientsChangePercent = ((statistic['clients_change'] / prevRow.clients) * 100).toFixed(2),
                        amountChangePercent = ((statistic['amount_change'] / prevRow.amount) * 100).toFixed(2),
                        gmrChangePercent = ((statistic['gmr_change'] / prevRow.gmr) * 100).toFixed(2);

                    statistics.push([
                        statistic.id,
                        {
                            icon: `${day}.${mouth}.${date.getFullYear()}`,
                            value: <span style={(timeStyles)}>{hours}:{minutes}</span>
                        },
                        {
                            value: `${new Intl.NumberFormat('uk-UK').format(statistic.clients)} (${clientsChangePercent}%)`,
                            customStyles: {
                                color: statistic['clients_change'] > 0 ? '#0E9C61' : 'var(--main-1)'
                            }
                        },
                        {
                            value: `${new Intl.NumberFormat('uk-UK').format(statistic.amount)} грн. (${amountChangePercent}%)`,
                            customStyles: {
                                color: statistic['amount_change'] > 0 ? '#0E9C61' : 'var(--main-1)'
                            }
                        },
                        {
                            value: `${statistic['gmr']} (${gmrChangePercent}%)`,
                            customStyles: {
                                color: statistic['gmr_change'] > 0 ? '#0E9C61' : 'var(--main-1)'
                            }
                        },
                    ]);
                });
                statistics = statistics.reverse();
                this.setState({
                    tableData: {
                        ...this.state.tableData,
                        body: statistics.slice(0, this.contentPerPage),
                        allData: statistics,
                        bodyLength: statistics.length
                    },
                    preloading: false
                })
            }
        }).catch(RemoveToken);
    }

    updatePagination = (page) => {
        const lastIndex = page * this.contentPerPage;
        const firstIndex = lastIndex - this.contentPerPage;

        this.setState({
            tableData: {
                ...this.state.tableData,
                body: this.state.tableData.allData.slice(firstIndex, lastIndex)
            }
        });
    }

    componentDidMount() {
        this.loadStatistics();
        seo({
            title: 'Venbest Map - Statistics'
        });
    }

    render() {
        return (
            <Wrapper customClass="main">
                <Navbar userType={this.props.userInfo.level} page={this.props.currentPage} logoutCb={this.props.logoutFn}/>
                <div className="content">
                    <div className="content__wrap">
                        <MainHead title="Статистика" userInfo={this.props.userInfo}/>
                        <Table
                            data={this.state.tableData}
                            customClass="main__table"
                            preloading={this.state.preloading}/>
                    </div>
                    <Pagination
                        contentPerPage={this.contentPerPage}
                        currentPage={this.state.page}
                        changePage={(val) => {
                            this.setState({page: val});
                            this.updatePagination(val);
                        }}
                        totalLength={this.state.tableData.bodyLength}
                    />
                </div>
            </Wrapper>
        );
    }
}

export default Statistics;