import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import Navbar from "../../components/UI/Navbar/Navbar";
import Table from "../../components/UI/Table/Table";
import Pagination from "../../components/UI/Pagination/Pagination";
import MainHead from "../../components/MainHead/MainHead";
import axios from "axios";
import Api from "../../Api/Api";
import RemoveToken from "../../Api/RemoveToken";
import {seo} from "../../helpers/seo";

class Clients extends React.Component {
    state = {
        page: 1,
        tableData: {
            head: ['ID', 'Контрагенты', 'Адрес', 'Компания', 'Абонплата', 'Координаты'],
            body: [],
            allData: [],
            bodyLength: 0
        },
        preloading: true
    }

    contentPerPage = 10
    updateUsers = () => {
        this.setState({preloading: true});
        axios({
            method: 'post',
            url: Api.link + 'clients/list',
            data: {
                phone: localStorage.getItem('phone'),
                token: localStorage.getItem('token')
            }
        }).then(res => {
            if (res.data.success) {
                let clients = [];
                res.data.data[0].forEach(client => {
                    clients.push([
                        client.id,
                        client.name,
                        client.address,
                        client.owner,
                        `${new Intl.NumberFormat('uk-UK').format(client.amount)} грн / мес.`,
                        {
                            value: <a href={`https://www.google.com/maps/place/${client.latitude},${client.longitude}`} target="_blank" rel="noreferrer">Координаты</a>,
                        }
                    ]);
                });
                this.setState({
                    tableData: {
                        ...this.state.tableData,
                        body: clients.slice(0, this.contentPerPage),
                        allData: clients,
                        bodyLength: clients.length
                    },
                    preloading: false
                });
            }
        }).catch(RemoveToken);
    }
    updatePagination = (page) => {
        const lastIndex = page * this.contentPerPage;
        const firstIndex = lastIndex - this.contentPerPage;

        this.setState({
            tableData: {
                ...this.state.tableData,
                body: this.state.tableData.allData.slice(firstIndex, lastIndex)
            }
        });
    }

    componentDidMount() {
        this.updateUsers();
        seo({
            title: 'Venbest Map - Clients'
        });
    }

    render() {
        return (
            <Wrapper customClass="main">
                <Navbar userType={this.props.userInfo.level} page={this.props.currentPage} logoutCb={this.props.logoutFn} />
                <div className="content">
                    <div className="content__wrap">
                        <MainHead userInfo={this.props.userInfo} title="Клиенты"/>
                        <Table data={this.state.tableData} customClass="main__table" preloading={this.state.preloading}/>
                    </div>
                    <Pagination
                        contentPerPage={this.contentPerPage}
                        currentPage={this.state.page}
                        changePage={(val) => {
                            this.setState({page: val});
                            this.updatePagination(val);
                        }}
                        totalLength={this.state.tableData.bodyLength} />
                </div>
            </Wrapper>
        );
    }
}

export default Clients;