import React from "react";
import InputMask from "react-input-mask";
import './PhoneInput.css'
import axios from "axios";
import Api from "../../../Api/Api";

class PhoneInput extends React.Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    onClickSubmit(e) {
        e.preventDefault();
        let
            data = {},
            errors = 0;

        for (let key of this.formRef.current.elements) {
            if (key.name) {
                const parsedPhoneNumber = key.value.replace(/\D+/g, '');
                if (this.validatePhone(parsedPhoneNumber)) {
                    data[key.name] = {};
                    data[key.name]['el'] = key;
                    data[key.name]['value'] = parsedPhoneNumber;
                    key.classList.remove('_error');
                } else {
                    errors++;
                    key.classList.add('_error');
                }
            }
        }

        if (!errors) this.sendForm(data);
    }

    sendForm = (data) => {
        axios({
            method: 'post',
            url: Api.link + 'login/send',
            data: {
                phone: data.phone.value
            }
        }).then(res => {
            if (res.data.success) {
                this.props.success();
                this.props.setPhone(data.phone.value);
            }
        }).catch(() => {
            data.phone.el.classList.add('_error');
        });
    }

    validatePhone(value) {
        const regex = /^\+?3?8?(0\d{9})$/;
        return regex.test(value);
    }

    render() {
        return (
            <form action="" className="form-phone" ref={this.formRef} onSubmit={this.onClickSubmit}>
                <div className="phone-input">
                    <label htmlFor="phone" className="label">Введите ваш номер телефона</label>
                    <InputMask
                        mask="+38 (099) 999 99 99"
                        placeholder="+38 (0**) *** ** **"
                        maskChar="*"
                        name="phone"
                        id="phone"
                        className="input"
                        onInput={(e) => e.target.classList.remove('_error')}
                    />
                    <button className="btn btn--primary">Дальше</button>
                </div>
            </form>
        );
    }
}

export default PhoneInput;