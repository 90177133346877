import React from 'react';
import './Grm.css';
import Wrapper from "../../components/Wrapper/Wrapper";
import Navbar from "../../components/UI/Navbar/Navbar";
import HaveBtnHead from "../../components/HaveBtnHead/HaveBtnHead";
import GrmConsole from "../../components/GrmConsole/GrmConsole";
import {ReactComponent as EditIcon} from "../../images/edit_icon.svg";
import {ReactComponent as TrashIcon} from "../../images/trash.svg";
import Table from "../../components/UI/Table/Table";
import Pagination from "../../components/UI/Pagination/Pagination";
import AddGrmModal from "../../components/Modals/GrmModals/AddGrmModal";
import axios from "axios";
import Api from "../../Api/Api";
import DangerModal from "../../components/Modals/DangerModal/DangerModal";
import toastr from "toastr";
import 'toastr/build/toastr.min.css';
import UpdateGrmModal from "../../components/Modals/GrmModals/UpdateGrmModal";
import RemoveToken from "../../Api/RemoveToken";
import {seo} from "../../helpers/seo";

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class Gmr extends React.Component {
    state = {
        openedModal: false,
        page: 1,
        tableData: {
            head: ['ID', 'Телефон', 'Имя', 'Город', 'Компания', 'Координаты', 'Ред', ''],
            body: [],
            allData: [],
            allDataNoParsed: [],
            bodyLength: 0
        },
        dangerOpened: false,
        currentGrm: {},
        updateOpened: false,
        preloading: true,
        clearFilter: false
    }

    contentPerPage = 10

    filtrationGmr = (city, company) => {
        let result = this.state.tableData.allDataNoParsed;
        if (city) result = result.filter(item => city === item.city);
        if (company) result = result.filter(item => company === item.company);
        this.updateGrms(result);
    }
    editGmrHandler = gmrData => {
        this.setState({
            currentGrm: gmrData,
            updateOpened: true,
        })
    }
    removeGmrHandler = gmrData => {
        this.setState({
            currentGrm: gmrData,
            dangerOpened: true
        })
    }
    removeGmr = () => {
        axios({
            method: 'post',
            url: Api.link + 'gmr/delete/',
            data: {
                token: localStorage.getItem('token'),
                phone: localStorage.getItem('phone'),
                id: this.state.currentGrm.id,
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    dangerOpened: false,
                    currentGmr: {},
                    page: 1
                });
                this.updateGrms();
                this.updatePagination(1);
                toastr.clear();
                toastr.success('ГМР успешно удалено');
            }
        }).catch(RemoveToken);
    }
    updateGrms = (data, isChanged = false) => {
        if (isChanged) {
            this.setState({preloading: true, clearFilter: true});
        } else {
            this.setState({preloading: true});
        }
        if (data) {
            this.parsing(data, false);
        } else {
            axios({
                method: 'post',
                url: Api.link + 'gmr/list',
                data: {
                    phone: localStorage.getItem('phone'),
                    token: localStorage.getItem('token')
                }
            }).then(res => {
                if (res.data.success) {
                    this.parsing(res.data.data, true);
                }
            }).catch(RemoveToken);
        }
    }
    parsing = (data, changeAllData) => {
        let gmrs = [];

        data.forEach(gmr => {
            if (gmr) gmrs.push([
                gmr.id,
                gmr.phone,
                gmr.name,
                gmr.city,
                gmr['company'],
                {
                    value: <a href={`https://www.google.com/maps/place/${gmr.latitude},${gmr.longitude}`}
                              target="_blank" rel="noreferrer">Координаты</a>,
                },
                {
                    icon: <EditIcon/>,
                    value: '',
                    cb: this.editGmrHandler.bind(this, gmr),
                    customStyles: {
                        cursor: 'pointer',
                    }
                },
                {
                    icon: <TrashIcon/>,
                    value: '',
                    cb: this.removeGmrHandler.bind(this, gmr),
                    customStyles: {
                        cursor: 'pointer'
                    }
                },
            ]);
        });
        if (changeAllData) {
            this.setState({
                tableData: {
                    ...this.state.tableData,
                    body: gmrs.slice(0, this.contentPerPage),
                    allData: gmrs,
                    allDataNoParsed: data,
                    bodyLength: gmrs.length
                },
                preloading: false
            });
        } else {
            this.setState({
                tableData: {
                    ...this.state.tableData,
                    body: gmrs.slice(0, this.contentPerPage),
                    allData: gmrs,
                    bodyLength: gmrs.length
                },
                preloading: false
            });
        }
    }
    updatePagination = (page) => {
        const lastIndex = page * this.contentPerPage;
        const firstIndex = lastIndex - this.contentPerPage;

        this.setState({
            tableData: {
                ...this.state.tableData,
                body: this.state.tableData.allData.slice(firstIndex, lastIndex)
            }
        });
    }

    componentDidMount() {
        this.updateGrms();
        seo({
            title: 'Venbest Map - Gmr'
        });
    }

    render() {
        const headTitleCustomStyle = {
            padding: '1px 18px',
            borderRadius: '14px',
            background: 'var(--white)'
        };

        return (
            <Wrapper customClass="main">
                <Navbar userType={this.props.userInfo.level} page={this.props.currentPage}
                        logoutCb={this.props.logoutFn}/>
                <div className="content grm-content">
                    <div className="content__wrap">
                        <HaveBtnHead
                            title={`${this.state.tableData.allData.length} ГМР`}
                            titleCustomStyle={headTitleCustomStyle}
                            userInfo={this.props.userInfo}
                            btnCb={() => this.setState({openedModal: true})}
                        />
                        <GrmConsole changeClearFilter={val => this.setState({clearFilter: val})} clearFilter={this.state.clearFilter} tableData={this.state.tableData.allDataNoParsed} filtratonFn={this.filtrationGmr}/>
                        <Table data={this.state.tableData} customClass="grm__table" preloading={this.state.preloading}/>
                    </div>
                    <Pagination
                        contentPerPage={this.contentPerPage}
                        currentPage={this.state.page}
                        changePage={(val) => {
                            this.setState({page: val});
                            this.updatePagination(val);
                        }}
                        totalLength={this.state.tableData.bodyLength}
                    />
                </div>
                <AddGrmModal
                    opened={this.state.openedModal}
                    closeCb={() => this.setState({openedModal: false})}
                    addCb={() => this.updateGrms(false, true)}
                />
                <UpdateGrmModal
                    opened={this.state.updateOpened}
                    closeCb={() => this.setState({updateOpened: false})}
                    addCb={this.updateGrms}
                    grmData={this.state.currentGrm}
                    clearData={() => this.setState({currentGrm: {}})}
                    updateData={(key, val) => {
                        const currentGrm = {
                            ...this.state.currentGrm
                        };
                        currentGrm[key] = val;
                        this.setState({currentGrm});
                    }}
                />
                <DangerModal
                    opened={this.state.dangerOpened}
                    closeCb={() => this.setState({dangerOpened: false})}
                    acceptCb={this.removeGmr}
                    title="Вы действительно хотите удалить этот ГМР?"
                />
            </Wrapper>
        );
    }
}

export default Gmr;