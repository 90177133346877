import React from "react";
import './CheckCode.css';
import ReactCodeInput from "react-code-input";
import axios from "axios";
import Api from "../../../Api/Api";
import { Navigate } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

class CheckCode extends React.Component {
    state = {
        code: '',
        validCode: true
    }

    checkCode = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            url: Api.link + 'login/check',
            data: {
                phone: this.props.phone,
                code: this.state.code
            }
        }).then(res => {
            if (res.data.success) {
                this.props.setToken(res.data.token);
                return <Navigate to='/'/>;
            }
        }).catch((error) => {
            console.log(error);
            this.setState({validCode: false});
        });

    }


    againSend = (e) => {
        e.preventDefault();
        if (this.props.phone) {
            axios({
                method: 'post',
                url: Api.link + 'login/send',
                data: {
                    phone: this.props.phone
                }
            }).then(res => {
                if (res.data.success) {
                    toastr.options = {
                        "closeButton": false,
                        "debug": false,
                        "newestOnTop": false,
                        "progressBar": true,
                        "positionClass": "toast-top-right",
                        "preventDuplicates": false,
                        "onclick": null,
                        "showDuration": "300",
                        "hideDuration": "1000",
                        "timeOut": "5000",
                        "extendedTimeOut": "1000",
                        "showEasing": "swing",
                        "hideEasing": "linear",
                        "showMethod": "fadeIn",
                        "hideMethod": "fadeOut"
                    };
                    toastr.clear();
                    toastr.success('Сообщение успешно отправлено');
                }
            }).catch((err) => console.log(err));
        }
    }

    render() {
        const codeProps = {
            className: 'check-code__pins',
            inputStyle: {
                fontFamily: 'Roboto',
                width: 'calc(100% / 6 - 50px / 6)',
                height: '50px',
                borderRadius: '10px',
                fontSize: '24px',
                textAlign: 'center',
                backgroundColor: 'transparent',
                color: 'var(--text-1)',
                border: '1px solid #9A9A9C',
                fontWeight: '500',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                transition: 'all .3s'
            },
            inputStyleInvalid: {
                fontFamily: 'Roboto',
                width: 'calc(100% / 6 - 50px / 6)',
                height: '50px',
                borderRadius: '10px',
                fontSize: '24px',
                textAlign: 'center',
                backgroundColor: 'transparent',
                fontWeight: '500',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                transition: 'all .3s',
                border: '1px solid var(--main-2)',
                color: 'var(--main-2)'
            }
        }

        return (
            <form action="" className="form-check-code" onSubmit={this.checkCode}>
                <div className="check-code">
                    <input type="hidden" name="code" value={this.state.code}/>
                    <label>
                        <span className="check-code__label label">Введите код</span>
                        <ReactCodeInput
                            type='text'
                            fields={6}
                            onChange={str => {
                                this.setState({code: str});
                                if(!this.state.validCode) this.setState({validCode: true});
                            }}
                            {...codeProps}
                            isValid={this.state.validCode}
                        />
                    </label>
                    <div className="check-code__problem-wrap">
                        <div className="check-code__problem-text">Не получили код?</div>
                        <div
                            className="check-code__problem"
                            onClick={this.againSend}
                        >Отправить заново
                        </div>
                    </div>
                    <div
                        className="check-code__change-phone"
                        onClick={() => this.props.changeNumber({isSent: false})}
                    >
                        Изменить номер телефона
                    </div>
                    <button className="btn btn--primary" type="submit">Войти</button>
                </div>
            </form>
        )
    }
}

export default CheckCode;