import './DangerModal.css';
import ModalOverlay from "../../UI/ModalOverlay/ModalOverlay";
import React from "react";

function DangerModal({opened, acceptCb, closeCb, title}) {
    return(
        <div className={`exit-modal${opened ? ' opened' : ''}`}>
            <div className="exit-modal__content">
                <div className="exit-modal__sub-title">оповещение</div>
                <h3 className="exit-modal__title">{title}</h3>
                <div className="exit-modal__buttons">
                    <button className="btn btn--close" onClick={closeCb}>Нет</button>
                    <button className="btn btn--accept" onClick={acceptCb}>Да</button>
                </div>
            </div>
            <ModalOverlay cb={closeCb}/>
        </div>
    );
}

export default DangerModal;