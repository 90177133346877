import './ItemGrmInfo.css';
import {ReactComponent as BriefcaseIcon} from "../../../../images/briefcase.svg";
import {ReactComponent as LocationIcon} from "../../../../images/location.svg";

function ItemGrmInfo({data}) {
    const
        name = data.name,
        companyName = data.companyName,
        location = data.location,
        distance = data.distance,
        price = data.price;

    return (
        <li className="grm-info__item">
            <div>
                <div className="grm-info__client-name">{name}</div>
                <div className="grm-info__company-name"><BriefcaseIcon/> {companyName}</div>
                <div className="grm-info__location"><LocationIcon/> {location}</div>
            </div>
            <div>
                <div className="grm-info__price">
                    {new Intl.NumberFormat('uk-UK').format(price)}
                    <span>грн. / мес.</span>
                </div>
                <div className="grm-info__distance">{distance}</div>
            </div>
        </li>
    );
}

export default ItemGrmInfo;